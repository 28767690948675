export default class CircleChartConfig {
    emptyLineVisible: boolean;
    endpointVisible: boolean;
    valueVisible: boolean;
    lineWidth: number;
    emptyLineWidth: number;
    fontSize: number;
    emptyColor: string;
    size: number;
    scoreVisible: boolean;
    importanceVisible: boolean;
    isEmptyState: boolean;

    constructor(obj: any) {
        this.emptyLineVisible = obj.hasOwnProperty("emptyLineVisible") && typeof obj.emptyLineVisible === "boolean" ? obj.emptyLineVisible : CircleChartConfigDefaults.emptyLineVisible;
        this.endpointVisible = obj.hasOwnProperty("endpointVisible") && typeof obj.endpointVisible === "boolean" ? obj.endpointVisible : CircleChartConfigDefaults.endpointVisible;
        this.valueVisible = obj.hasOwnProperty("valueVisible") && typeof obj.valueVisible === "boolean" ? obj.valueVisible : CircleChartConfigDefaults.valueVisible;
        this.lineWidth = obj.hasOwnProperty("lineWidth") && typeof obj.lineWidth === "number" ? obj.lineWidth : 0.025 * obj.chartWidth;
        this.emptyLineWidth = this.getEmptyLineWidth(this.lineWidth);
        this.fontSize = obj.hasOwnProperty("fontSize") && typeof obj.fontSize === "number" ? obj.fontSize : CircleChartConfigDefaults.fontSize;
        this.emptyColor = obj.hasOwnProperty("emptyColor") && typeof obj.emptyColor === "boolean" ? obj.emptyColor : CircleChartConfigDefaults.emptyColor;
        this.size = obj.hasOwnProperty("size") && typeof obj.size === "number" ? obj.size : CircleChartConfigDefaults.size;
        this.scoreVisible = obj.hasOwnProperty("scoreVisible") && typeof obj.scoreVisible === "boolean" ? obj.scoreVisible : CircleChartConfigDefaults.scoreVisible;
        this.importanceVisible = obj.hasOwnProperty("importanceVisible") && typeof obj.importanceVisible === "boolean" ? obj.importanceVisible : CircleChartConfigDefaults.importanceVisible;
        this.isEmptyState = obj.hasOwnProperty("isEmptyState") && typeof obj.isEmptyState === "boolean" ? obj.isEmptyState : CircleChartConfigDefaults.isEmptyState;
    }

    getEmptyLineWidth(lineWidth: number): number {
        return CircleChartConfigDefaults.linesRatio * lineWidth;
    }
}

const CircleChartConfigDefaults = {
    emptyLineVisible: true,
    endpointVisible: true,
    valueVisible: true,
    lineWidth: 8,
    fontSize: 12,
    emptyColor: "#eeeded",
    linesRatio: 0.4,
    size: 5,
    scoreVisible: true,
    importanceVisible: true,
    isEmptyState: false
};
