<template>
    <div class="dashboard-sessions section-card">
        <div class="dashboard-sessions__header">
            <div class="dashboard-sessions__header--title">
                {{ $t("Feedback Sessions") }}
            </div>
            <router-link to="/business/feedback/feedback-sessions">
                {{ $t("View All") }}
            </router-link>
        </div>

        <div class="dashboard-sessions__tabs">
            <div class="dashboard-sessions__tabs--header">
                <div class="dashboard-sessions__tabs--heading">
                    <span>{{ $t("Live") }}</span>
                    <strong>{{ fillData.length }}</strong>
                </div>
                <div class="dashboard-sessions__tabs--arrows" v-if="fillData.length">
                    <button @click="handlePrev" :disabled="isPrevDisabled">
                        <inline-svg src="/media/buying/icons/carousel/line-arrow-left.svg" />
                    </button>
                    <button @click="handleNext" :disabled="isNextDisabled">
                        <inline-svg src="/media/buying/icons/carousel/line-arrow-right.svg" />
                    </button>
                </div>
            </div>

            <div class="dashboard-sessions__tabs--sessions" v-if="fillData.length">
                <div
                    class="dashboard-sessions__tabs--carousel"
                    ref="carouselWrapper"
                    @scroll="handleScroll"
                >
                    <div class="carousel-wrapper" ref="carousel">
                        <div ref="carouselItem" v-for="(item, index) in fillData" :key="index">
                            <DashboardSessionItem :feedbackSession="item" />
                        </div>
                    </div>
                </div>
            </div>

            <DashboardEmptyState
                v-else
                icon="/media/buying/icons/no-session-found.svg"
                :title="$t('No Sessions Found')"
                :text="$t('There are no active/live feedback sessions')"
                :button-text="currentUser.can(BusinessUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION) ? $t('Create Feedback ') : ''"
                button-link="/business/feedback/feedback-session/create"
                class-name="py-5"
            />
        </div>
    </div>
</template>

<script>
import DashboardSessionItem from "@/buying-teams/pages/business/dashboard/components/overview/DashboardSessionItem";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";
import store from "@/store";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "DashboardSessions",
    components: {
        DashboardEmptyState,
        DashboardSessionItem,
    },
    props: {
        fillData: Array
    },
    data() {
        return {
            isPrevDisabled: false,
            isNextDisabled: false,
            cardItemWidth: 300,
            BusinessUserPermissionTypeEnum,
        };
    },
    computed: {
        currentUser() {
            return store.getters.currentUser;
        }
    },
    mounted() {
        this.setCarouselSettings();
        window.addEventListener('resize', this.setCarouselSettings);
    },
    unmounted() {
        window.removeEventListener('resize', this.setCarouselSettings);
    },
    methods: {
        handlePrev() {
            let element = this.$refs.carouselWrapper;
            if (!element) return;
            if (this.$refs.carouselWrapper.scrollLeft > 0) {
                this.$refs.carouselWrapper.scrollLeft -= this.cardItemWidth;
            }
        },
        handleNext() {
            let element = this.$refs.carouselWrapper;
            if (!element) return;

            this.$refs.carouselWrapper.scrollLeft += this.cardItemWidth;
        },
        setCarouselSettings() {
            this.$nextTick(() => {
                const windowWidth = window.innerWidth;
                const carousel = this.$refs.carousel;
                const carouselItem = this.$refs.carouselItem;
                const carouselWrapper = this.$refs.carouselWrapper;
                if (carousel && carouselItem && carouselWrapper) {
                    const carouselClientWidth = carouselWrapper.clientWidth;
                    let carouselItemWidth = carouselClientWidth;

                    carouselItem.forEach((item) => {
                        if (windowWidth >= 600 && windowWidth < 1500) {
                            carouselItemWidth = carouselClientWidth / 2
                        } else if (windowWidth >= 1500) {
                            carouselItemWidth = carouselClientWidth / 3
                        }
                        item.style.width = `${carouselItemWidth}px`
                    });

                    this.cardItemWidth = carouselItemWidth;
                }
                this.handleScroll();
            });
        },
        handleScroll() {
            let element = this.$refs.carouselWrapper;
            if (!element) return;

            const scrollLeft = this.$refs.carouselWrapper.scrollLeft;
            const scrollWidth = this.$refs.carouselWrapper.scrollWidth - this.$refs.carouselWrapper.offsetWidth;
            this.isPrevDisabled = scrollLeft <= 0;
            this.isNextDisabled = scrollLeft >= scrollWidth;
        }
    }
};
</script>

<style lang="scss">
.dashboard-sessions {
    height: 100%;
    padding: 21px 12px 0 25px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 14px;
        padding-bottom: 21px;
        border-bottom: 2px solid #F0F0F0;

        &--title {
            font-weight: 700;
            font-size: 18px;
            line-height: 13px;
            color: #000000;
            opacity: 0.87;
        }

        a {
            font-weight: 700;
            font-size: 16px;
            line-height: 13px;
            color: #435BF4;
            opacity: 0.87;
        }
    }

    &__tabs {
        position: relative;

        &--header {
            height: 46px;
            border-bottom: 2px solid #F0F0F0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &--heading {
            display: flex;
            align-items: center;

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 13px;
                color: #8B8B8B;
                opacity: 0.87;
                margin-right: 6px;
                text-transform: uppercase;
            }

            strong {
                background: #E9E9E9;
                border-radius: 4px;
                padding: 3px 4px;
                font-weight: 700;
                font-size: 14px;
                line-height: 13px;
                color: #000000;
            }
        }

        &--arrows {
            display: flex;
            align-items: center;
            gap: 20px;
            height: 45px;
            padding-right: 12px;

            button {
                background: transparent;
                border: none;
                padding: 0;

                svg path {
                    fill: #435BF4;
                }

                &:disabled svg path {
                    fill: #7E8299;
                }
            }
        }

        &--sessions {
            position: relative;
            &:before, &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                background: #fff;
                width: 1px;
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }
        }

        &--carousel {
            overflow: hidden;
            height: calc(100% - 46px);
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                display: none;
            }

            .dashboard-session-item {
                border-right: 1px solid #F0F0F0;
                border-left: 1px solid #F0F0F0;
            }

            .carousel-wrapper {
                display: inline-flex;
                min-width: 100%;
            }

            .carousel {
                height: 100%;
                margin-left: -21px;
                margin-right: -21px;

                .carousel__viewport {
                    height: 100%;

                    .carousel__track {
                        height: 100%;

                        .carousel__slide {
                            border-right: 1px solid #F0F0F0;
                            border-left: 1px solid #F0F0F0;
                        }
                    }
                }

                .carousel__prev,
                .carousel__next {
                    top: 0;
                }
            }
        }
    }
}
</style>
