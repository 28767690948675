import CircleChartModel from "@/store/models/CircleChartModel";
import { SurveyModel } from "@/store/models/SurveyModel";
import { BusinessCommonListEnum } from "@/store/enums/BusinessCommonListEnum";
import { BusinessCommonListItem } from "@/store/models/shared/BusinessCommonListItem";
import { RequestModel } from "@/store/models/request/RequestModel";
import { IdeaModel } from "@/store/models/request/IdeaModel";
import { CountryUtil } from "@/core/utils/CountryUtil";
import { v4 as uuidv4 } from 'uuid';
import { BusinessDashboardQuestionItem } from "@/store/models/business/dashboard/BusinessDashboardQuestionItem";
import { BusinessDashboardOrderDirectionsEnum } from "@/store/enums/business/BusinessDashboardOrderDirectionsEnum";

export class BusinessDashboardOverviewContainer {
    scoreSectionData: any;
    feedbackSessionsSectionData?: SurveyModel[];
    questionsSectionData: any = {
        questions: [],
        isLoading: false,
        pagination: null,
        page: 0,
        limit: 50,
        orderKey: 'lowest_score',
        orderDirection: BusinessDashboardOrderDirectionsEnum.DESC,
    };
    banksSectionData: any;
    countriesSectionData: any;
    productAreasSectionData: any;
    requestIdeaSectionData: {
        requests: {
            critical: RequestModel[],
            high: RequestModel[],
            normal: RequestModel[],
            low: RequestModel[],
        } | null,
        ideas: IdeaModel[],
        requestsCount: number,
        ideasCount: number
    };

    constructor(obj: any) {
        this.scoreSectionData = {
            chartData: new CircleChartModel(obj.bank_score),
            respondents: obj.bank_score.respondents,
            scores: obj.bank_score.scores
        };
        this.feedbackSessionsSectionData = obj.surveys.map(survey => new SurveyModel(survey));
        this.banksSectionData = this.getBanksSectionData(obj.banks_data);
        this.countriesSectionData = this.getCountriesSectionData(obj.countries_data);
        this.productAreasSectionData = this.getProductAreasSectionData(obj.source_product_area_data);
        this.requestIdeaSectionData = this.getRequestIdeaSectionData(obj.requests, obj.ideas);
    }

    getBanksSectionData(banksData: any) {
        if (!banksData) {
            return [];
        }
        return banksData.map(bkd => new BusinessCommonListItem({
            ...bkd,
            type: BusinessCommonListEnum.BANKS,
            title: bkd.name,
            stats: bkd.scores
        }));
    }

    getCountriesSectionData(countriesData) {
        let res = [] as any[];

        if(countriesData) {
            countriesData.forEach(ctd => {
                res.push({
                    country: {
                        name: ctd.country,
                        imagePath: CountryUtil.getCountryImage(ctd.country),
                        uuid: uuidv4(),
                        stats: ctd.stats
                    },
                    banksData: ctd.banks.map(bkd => new BusinessCommonListItem({
                        ...bkd,
                        title: bkd.name,
                        type: BusinessCommonListEnum.BANKS,
                        stats: {
                            score: bkd.score,
                            importance: bkd.importance
                        }
                    }))
                })
            })
        }

        return res;
    }

    getProductAreasSectionData(productAreasData: any) {
        if (!productAreasData) {
            return [];
        }
        return productAreasData.map(prd => new BusinessCommonListItem({
            ...prd,
            type: BusinessCommonListEnum.PRODUCT_AREA,
            title: prd.name,
            stats: prd.scores
        }));
    }

    getRequestIdeaSectionData(requestsData: any, ideasData: any) {
        let ideas = [];
        let requests = {
            critical: [],
            high: [],
            normal: [],
            low: [],
        };
        let requestsCount = 0;
        let ideasCount = 0;

        if (requestsData) {
            for (let [type, data] of Object.entries(requestsData)) {
                // @ts-ignore
                requests[type] = data && data.length ? data.map(rq => new RequestModel(rq)) : [];
            }

            // @ts-ignore
            requestsCount = Object.values(requests).reduce((a, e: any) => a + e.length, 0)
        }

        if (ideasData) {
            ideas = ideasData.map(id => new IdeaModel(id));
            ideasCount = ideas.length;
        }

        return { requests, ideas, requestsCount, ideasCount };
    }

    handleSorting(pageType, sorting) {
        let dataForSorting;

        switch (pageType) {
            case BusinessCommonListEnum.BANKS:
                dataForSorting = this.banksSectionData;
                break;
            case BusinessCommonListEnum.PRODUCT_AREA:
                dataForSorting = this.productAreasSectionData;
                break;
        }

        if (dataForSorting) this.sort(dataForSorting, sorting);
    }

    sort(data, sorting) {
        switch (sorting) {
            case 'highest_score':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li2.stats.score - li1.stats.score);
                break;
            case 'lowest_score':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li1.stats.score - li2.stats.score);
                break;
            case 'highest_importance_score':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li2.stats.importance - li1.stats.importance);
                break;
            case 'lowest_importance_score':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li1.stats.importance - li2.stats.importance);
                break;
            case 'highest_negative_gap':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li1.stats.gap - li2.stats.gap);
                break;
            case 'highest_positive_gap':
                data = data.sort((li1: BusinessCommonListItem, li2: BusinessCommonListItem) => li2.stats.gap - li1.stats.gap);
                break;
        }
    }

    sortCountries(byWhat) {
        switch (byWhat) {
            case 'highest_score':
                this.countriesSectionData = this.countriesSectionData.sort((li1: any, li2: any) => li2.country.stats.score - li1.country.stats.score);
                break;
            case 'lowest_score':
                this.countriesSectionData = this.countriesSectionData.sort((li1: any, li2: any) => li1.country.stats.score - li2.country.stats.score);
                break;
            case 'highest_importance_score':
                this.countriesSectionData = this.countriesSectionData.sort((li1: any, li2: any) => li2.country.stats.importance - li1.country.stats.importance);
                break;
            case 'lowest_importance_score':
                this.countriesSectionData = this.countriesSectionData.sort((li1: any, li2: any) => li1.country.stats.importance - li2.country.stats.importance);
                break;
            case 'highest_negative_gap':
                this.countriesSectionData = this.countriesSectionData.sort((li1: any, li2: any) => li1.country.stats.gap - li2.country.stats.gap);
                break;
            case 'highest_positive_gap':
                this.countriesSectionData = this.countriesSectionData.sort((li1: any, li2: any) => li2.country.stats.gap - li1.country.stats.gap);
                break;
        }
    }

    addQuestionsSectionData (questionData, paginationData, isForced) {
        if (!this.questionsSectionData.questions || isForced) {
            this.questionsSectionData.questions = [];
        }
        this.questionsSectionData.pagination = paginationData;
        questionData.forEach(dt => {
            this.questionsSectionData.questions.push(new BusinessDashboardQuestionItem(dt));
        })
    }
}
