<template>
    <div class="dashboard-requests-section">
        <el-tabs>
            <el-tab-pane v-for="(item, index) in requests" :key="index">
                <template #label>
                    <div class="custom-tabs-label" :class="'tab-' + index">
                        <span class="custom-tabs-label__name">{{ index }}</span>
                        <span class="custom-tabs-label__count">{{ requests[index].length }}</span>
                    </div>
                </template>

                <div class="dashboard-requests-section__content">
                    <template v-if="requests[index].length">
                        <RequestIdeaItemCard
                            v-for="item of requests[index]"
                            :key="item"
                            :fillData="item"
                        />
                    </template>
                    <DashboardEmptyState
                        v-else
                        icon="/media/buying/icons/no-request-found.svg"
                        :title="$t('No Open Requests Found')"
                        :text="$t('Your company don\'t have any open requests for this bank. Requests can be anything from blocked payments to other issues. Create requests to keep track of issues.')"
                        :button-text="$t('Create Request')"
                        button-link="/business/feedback/request/create"
                        class-name="mb-10"
                    />
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import RequestIdeaItemCard from "@/buying-teams/pages/business/dashboard/components/overview/RequestIdeaItemCard";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";

export default {
    name: "DashboardRequestsSection",
    components: {
        DashboardEmptyState,
        RequestIdeaItemCard
    },
    props: {
        requests: Object
    }
};
</script>

<style lang="scss">
.dashboard-requests-ideas {
    min-height: 500px;

    .dashboard-requests-section {
        &__content {
            max-height: 386px;
            overflow-y: auto;
            margin-right: -23px;
            padding-right: 23px;
        }

        .el-tabs__header {
            padding: 0;
        }

        .el-tabs__item {
            height: 32px;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            padding: 0 15px;

            &.is-active,
            &:hover {
                .custom-tabs-label__name {
                    color: #000000;
                }
            }
        }

        .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
            .custom-tabs-label {
                padding-left: 5px;
            }
        }

        .el-tabs__content {
            overflow: revert;
        }

        .custom-tabs-label {
            &__name {
                padding-left: 15px;
                position: relative;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: rgba(0, 0, 0, 0.5);
                text-transform: capitalize;

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                }
            }

            &__count {
                background: #ECECEC;
                border-radius: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                padding: 2px 5px;
                margin-left: 8px;
            }

            &.tab-critical .custom-tabs-label__name:after {
                background: #D12323;
                animation: pulse-red 2s infinite;
            }

            &.tab-high .custom-tabs-label__name:after {
                background: #DE6B00;
            }

            &.tab-normal .custom-tabs-label__name:after {
                background: #0C5FDC;
            }

            &.tab-low .custom-tabs-label__name:after {
                background: #28A57F;
            }
        }
    }

    @keyframes pulse-red {
        0% {
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
        }

        70% {
            box-shadow: 0 0 0 6px rgba(255, 82, 82, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
        }
    }
}
</style>
