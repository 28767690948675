<template>
  <div class="dashboard-requests-ideas section-card">
    <div class="view-all-button" @click="handleViewAll">
      {{ $t('View All') }}
    </div>
    <el-tabs v-model="activeTab">
      <el-tab-pane :name="tabConst.REQUEST">
        <template #label>
          <inline-svg src="/media/buying/icons/request-black.svg" />
          <span>{{ $t('Open Requests') }}</span>
          <strong>{{ requestIdeaSectionData.requestsCount }}</strong>
        </template>

        <div class="requests-ideas-tab-content">
          <DashboardRequestsSection
            :requests="requestIdeaSectionData.requests"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane :name="tabConst.IDEAS">
        <template #label>
          <inline-svg src="/media/buying/icons/idea-black.svg" />
          <span>{{ $t('Open Ideas') }}</span>
          <strong>{{ requestIdeaSectionData.ideasCount }}</strong>
        </template>

        <div class="requests-ideas-tab-content for-idea">
          <template
              v-if="requestIdeaSectionData.ideasCount"
          >
            <RequestIdeaItemCard
                v-for="(item, index) in requestIdeaSectionData.ideas"
                :key="index"
                :fillData="item"
            />
          </template>
          <DashboardEmptyState
            v-else
            icon="/media/buying/icons/no-idea-found.svg"
            :title="$t('No Open Ideas Found')"
            :text="$t('Your company don\'t have any open ideas for this bank. Ideas can be anything from blocked payments to other issues. Create ideas to keep track of issues.')"
            :button-text="$t('Create Idea')"
            button-link="/business/feedback/request/create"
            class-name="mb-10"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import DashboardRequestsSection from "@/buying-teams/pages/business/dashboard/components/overview/DashboardRequestsSection";
import RequestIdeaItemCard from "@/buying-teams/pages/business/dashboard/components/overview/RequestIdeaItemCard";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";

const tabConst = {
  REQUEST: 'requests',
  IDEAS: 'ideas'
}

export default {
  name: "DashboardRequestsIdeas",
  components: {
    DashboardEmptyState,
    RequestIdeaItemCard,
    DashboardRequestsSection
  },
  props: {
    requestIdeaSectionData: Object
  },
  data() {
    return {
      activeTab: tabConst.REQUEST,
      tabConst,
    }
  },
  methods: {
    handleViewAll() {
      switch (this.activeTab) {
        case tabConst.REQUEST:
          this.$router.push('/business/feedback/requests');
          break;
        case tabConst.IDEAS:
          this.$router.push('/business/feedback/ideas');
          break;
      }
    }
  }
}
</script>

<style lang="scss">
.dashboard-requests-ideas {
  position: relative;
  .view-all-button {
    position: absolute;
    z-index: 2;
    top: 31px;
    right: 23px;
    font-weight: 700;
    font-size: 16px;
    line-height: 13px;
    color: #435BF4;
    opacity: 0.87;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .el-tabs__header {
    padding: 13px 23px 0 13px;
    margin-bottom: 0;
  }
  .el-tabs--top .el-tabs__item {
    height: 48px;
    display: inline-flex;
    align-items: center;
    > span {
      font-weight: 700;
      font-size: 18px;
      line-height: 13px;
      color: #000000;
      opacity: 0.87;
      margin: 0 8px 0 11px;
    }
    > strong {
      background: #ECECEC;
      border-radius: 3px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      padding: 3px;
    }
    &.is-top:nth-child(2) {
      margin-left: 11px;
    }
    &.is-active {
      > span {
        color: #435BF4;
      }
      > strong {
        background: #435BF4;
        color: #fff;
      }
      > svg path {
        fill: #435BF4;
      }
    }
  }
  .requests-ideas-tab-content {
    padding-left: 13px;
    padding-right: 23px;
    &.for-idea {
      max-height: 433px;
      overflow-y: auto;
    }
  }

  @media (max-width: 600px) {
    padding-top: 10px;
    .view-all-button {
      top: 10px;
      font-size: 14px;
    }
  }
}
</style>
