<template>
    <div class="dashboard-countries section-card">
        <div class="dashboard-countries__header section-card-header">
            <div class="section-card-title">
                <img src="/media/buying/icons/map-black-icon.svg" alt="">
                <h2>{{ $t("Countries") }}</h2>
                <span>{{ countriesSectionData.length }}</span>
            </div>
            <div class="section-card-actions">
                <router-link to="/business/dashboard/countries" v-if="!countriesSectionData.length">{{ $t("View All")
                    }}
                </router-link>

                <DashboardSortSelect
                    v-model="customersSorting"
                    :disabled="!countriesSectionData.length"
                    @onSortingChange="handleCountriesSort"
                />
            </div>
        </div>

        <div class="dashboard-countries__body" v-if="countriesSectionData.length">
            <transition-group tag="div" name="group-transition" class="countries-list">
                <div class="countries-list__item" v-for="item in countriesSectionData" :key="item.country.uuid">
                    <div class="countries-list__item--top">
                        <a
                            :href="`/business/dashboard/countries/${item.country.name}`"
                            @click.prevent="handleCountryItemClick(item.country.name)"
                        >
                            <div class="country-title">
                                {{ item.country.name }}
                            </div>
                        </a>
                        <!--            <div class="country-label">[Sort Applied] <b>#3</b></div>-->
                        <a
                            :href="`/business/dashboard/countries/${item.country.name}`"
                            @click.prevent="handleCountryItemClick(item.country.name)"
                        >
                            <div class="country-image">
                                <CountryImage
                                    :imagePath="item.country.imagePath"
                                    :score="item.country.stats.score"
                                />
                            </div>
                        </a>

                        <div class="list-stats">
                            <div class="list-stats-item stats-score" :class="getStatusClass(item.country.stats.score)">
                                <div class="list-stats-label"></div>
                                <span>{{ $t("Score") }}</span>
                                <p>{{ numberDecimalsFormat(item.country.stats.score) }}</p>
                            </div>
                            <div class="list-stats-item stats-imp">
                                <div class="list-stats-label"></div>
                                <span>{{ $t("Imp") }}</span>
                                <p>{{ numberDecimalsFormat(item.country.stats.importance) }}</p>
                            </div>
                            <div class="list-stats-item stats-gap" :class="getGapStatusClass(item.country.stats.gap)">
                                <div class="list-stats-label"></div>
                                <span>{{ $t("Gap") }}</span>
                                <p>{{ numberDecimalsFormat(item.country.stats.gap) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="countries-list__item--body">
                        <div class="banks-title">
                            {{ $t(`Your top COUNT Bank${item.banksData.length > 1 ? "s" : ""} in COUNTRY`, {
                            country: item.country.name,
                            count: item.banksData.length
                        }) }}
                        </div>
                        <BankProductAreaList
                            has-image
                            hide-gap
                            hide-arrow
                            :data-list="item.banksData"
                            @onItemClick="handleBankItemClick"
                        />
                    </div>
                </div>
                <div class="countries-list__item add-more-country" :key="0">
                    <div class="countries-list__item--top">
                        <div class="country-title">{{ $t("Add More Country(s)?") }}</div>
                        <div class="country-label">{{ $t("You can add more countries using the button below") }}</div>
                        <router-link to="/business/settings/user-settings/overview" class="country-button">
                            {{ $t("Add Country") }}
                        </router-link>
                    </div>
                    <div class="countries-list__item--body">
                        <img src="/media/buying/add-more-country.png" alt="">
                    </div>
                </div>
            </transition-group>
        </div>
        <DashboardEmptyState
            v-else
            icon="/media/buying/icons/empty-state-country.svg"
            :title="$t('No Country Found')"
            :text="$t('Your company don\'t have any data for the countries selected. Create a feedback session to start reviewing and get insights into your banks performance.')"
            :button-text="$t('Add Country')"
            button-link="/business/settings/user-settings/overview"
            button-icon="/media/buying/icons/plus.svg"
            class-name="pt-5 pb-10"
        />
    </div>
</template>

<script>
import { getStatusClass, getGapStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import BankProductAreaList from "@/buying-teams/pages/business/dashboard/components/overview/BankProductAreaList";
import { scoresSortingLevels } from "@/buying-teams/pages/bank/dashboard/dashboardData";
import DashboardSortSelect from "@/buying-teams/shared-components/inputs/DashboardSortSelect";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";
import CountryImage from "@/buying-teams/shared-components/charts/CountryImage";
import store from "@/store";

export default {
    name: "DashboardCountries",
    components: {
        CountryImage,
        DashboardEmptyState,
        BankProductAreaList,
        DashboardSortSelect
    },
    props: {
        countriesSectionData: Array,
        dataContainer: Object,
        filterData: Object,
    },
    data() {
        return {
            getStatusClass,
            getGapStatusClass,
            customersSorting: "lowest_score",
            numberDecimalsFormat
        };
    },
    computed: {
        customersOptions() {
            return scoresSortingLevels;
        },
    },
    methods: {
        handleCountriesSort(byWhat) {
            this.dataContainer.sortCountries(byWhat);
        },
        handleCountryItemClick(name) {
            const filters = {...this.filterData};
            delete filters.countries;
            store.commit('SAVE_BUSINESS_SINGLE_COUNTRY_DASHBOARD_FILTER', filters);
            this.$router.push(`/business/dashboard/countries/${name}`);
        },
        handleBankItemClick(item) {
            const filters = {...this.filterData};
            delete filters.bank_ids;
            store.commit('SAVE_BUSINESS_SINGLE_BANK_DASHBOARD_FILTER', filters);
            this.$router.push(`/business/dashboard/banks/${item.id}`);
        }
    }
};
</script>

<style lang="scss">
.dashboard-countries {
    overflow: hidden;

    &__header {
        margin-bottom: 20px;
    }

    &__body {
        overflow-x: auto;

        .countries-list {
            display: flex;
            width: fit-content;
            position: relative;
            z-index: 1;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                height: 325px;
                background: #FAFAFA;
            }

            &__item {
                width: 280px;
                position: relative;
                z-index: 1;

                &:not(:last-child) {
                    border-right: 1.98159px solid #F0F0F0;
                }

                &--top {
                    padding: 0 17px 17px;

                    .list-stats {
                        gap: 10px;
                        justify-content: space-between;
                    }
                }

                &--body {
                    background: #FAFAFA;
                    padding-bottom: 19px;

                    .banks-title {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 15px;
                        color: rgba(0, 0, 0, 0.5);
                        padding-top: 25px;
                        padding-left: 21px;
                        margin-bottom: 14px;
                    }

                    .list-section__item {
                        &.has-image {
                            padding-left: 18px;
                        }

                        &:last-child:before {
                            display: block;
                        }
                    }
                }

                .country-title {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: #343434;
                    margin-bottom: 7px;
                    margin-left: 14px;
                }

                .country-label {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                    opacity: 0.5;
                    margin-left: 14px;
                }

                .country-image {
                    height: 201px;
                    background: #F9F9FF;
                    border-radius: 10px;
                    margin: 12px 5px 8px 9px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;

                    img {
                        opacity: 0.2;
                    }
                }

                &.add-more-country {
                    border-right: 1.98159px solid #F0F0F0;
                    width: 301px;

                    .countries-list__item--body {
                        height: 325px;
                        padding-bottom: 0;

                        img {
                            width: 100%;
                        }
                    }

                    .countries-list__item--top {
                        height: 276px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .country-label {
                            text-align: center;
                            margin-bottom: 23px;
                        }

                        .country-button {
                            background: #F4F5FE;
                            border-radius: 81px;
                            border: none;
                            height: 39px;
                            min-width: 123px;
                            padding: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 15px;
                            color: #435BF4;
                            transition: 0.3s ease-in-out;

                            &:hover {
                                background: #e9ecff;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
