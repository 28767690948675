<template>
    <div class="text-center">
        <h3 v-if="labelTop" class="subtitle">{{ labelTop }}</h3>
        <div class="ring">
            <canvas :id="'chart_'+ elementId" :width="chartConfigs.width" :height="chartConfigs.height"></canvas>
        </div>
        <h5 v-if="labelBottom" class="subtitle">{{ labelBottom }}</h5>
    </div>
</template>

<script>

import CircleChartConfig from "@/store/models/CircleChartConfig";
import CircleChartModel from "@/store/models/CircleChartModel";
import { v4 as uuidv4 } from "uuid";
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";

export default {
    name: "CircleChart",
    components: { CustomLoader },
    props: {
        labelTop: String,
        labelBottom: String,
        chartData: {
            type: CircleChartModel,
            required: true
        },
        chartConfigs: {
            type: Object,
            required: true
        },
        values: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            all: 2 * Math.PI,
            startPoint: 5 * Math.PI / 6,
            endPoint: 11 * Math.PI / 6,
            bigVal: this.chartData.score,
            smallVal: this.chartData.importance,
            elementId: uuidv4(),
            configs: null,
            VALUE_TYPES: {
                SCORE: "score",
                IMPORTANCE: "importance"
            }
        };
    },
    mounted() {
        setTimeout(() => {
            this.drawChart();
        }, 300);
    },

    computed: {
        getDashboardLoading() {
            return store.getters.getDashboardLoading;
        }
    },
    methods: {
        async drawChart() {
            let bigR = this.chartConfigs.width / 3;

            // TODO add color selectable from configs (change input values with colors)
            let lines = [];
            this.values.forEach((vl, index) => {
                lines.push({
                    radius: lines[lines.length - 1] ? 0.62 * lines[lines.length - 1].radius : bigR,
                    value: vl,
                    color: index === 0 ? null : "#D1D1D1",
                    type: index === 0 ? this.VALUE_TYPES.SCORE : this.VALUE_TYPES.IMPORTANCE
                });
            });
            let startPosX = this.chartConfigs.width / 2;
            let startPosY = 0.6 * this.chartConfigs.height;

            let canvas = document.getElementById("chart_" + this.elementId);
            if (canvas) {
                let context = canvas.getContext("2d");

                this.configs = new CircleChartConfig({
                    valueVisible: this.chartConfigs.valueVisible,
                    emptyLineVisible: this.chartConfigs.emptyLineVisible,
                    endpointVisible: this.chartConfigs.endpointVisible,
                    fontSize: this.chartConfigs.fontSize,
                    chartWidth: this.chartConfigs.width,
                    size: this.chartConfigs.size,
                    isEmptyState: this.chartData.isEmpty
                });

                lines.forEach(ln => {
                    this.drawLine(context, startPosX, startPosY, ln.radius, ln.value, ln.color, this.configs);
                });

                this.drawCenteredValues(context, lines, this.chartConfigs.width, this.chartConfigs.height, this.configs);
            }

        },
        drawEmptyLine(context, startPosX, startPosY, radius, configs) {
            context.beginPath();
            let emptyLine = this.startPoint + Math.PI + Math.PI / 3;
            context.arc(startPosX, startPosY, radius, this.startPoint, emptyLine);
            context.lineWidth = 3;
            context.strokeStyle = configs.emptyColor;
            context.stroke();
        },
        drawLine(context, startPosX, startPosY, radius, value, color, configs) {
            if (!configs) configs = new CircleChartConfig();

            if (configs.emptyLineVisible) {
                this.drawEmptyLine(context, startPosX, startPosY, radius, configs);
            }

            context.beginPath();
            let end = this.startPoint + this.getRadiusRelatedSize(value) * Math.PI;
            context.arc(startPosX, startPosY, radius, this.startPoint, end);
            context.lineWidth = configs.lineWidth;
            if (!color) color = this.getColor(value);
            context.strokeStyle = color;
            context.stroke();

            let [m, n] = this.getPointFromArc(startPosX, startPosY, radius, end);
            let [a, b] = this.getPointFromArc(startPosX, startPosY, radius, this.startPoint);
            if (configs.endpointVisible && !configs.isEmptyState) {
                this.drawStartPoint(context, a, b, configs.lineWidth / 2, value, color);
                this.drawCircle(context, m, n, 6);
                this.drawCircleWhite(context, m, n, 5.9);
            }
            if (configs.valueVisible) {
                this.drawLineValue(context, value, configs.fontSize, m, n);
            }
        },
        drawLineValue(context, value, size, m, n) {
            context.font = `bold ${size}px Arial`;
            context.fillStyle = "#000000";
            context.fillText(value, m + 5, n - 10);
            context.stroke();
            context.fillStyle = "#ffffff";
            context.fill();
        },
        drawCircle(context, posx, posy, size) {
            context.beginPath();
            context.fillStyle = "#0000FF";
            context.arc(posx, posy, size, 0, 2 * Math.PI);
            context.stroke();
        },
        drawCircleWhite(context, posx, posy, size) {
            context.beginPath();
            context.fillStyle = "#ffffff";
            context.arc(posx, posy, size, 0, 2 * Math.PI);
            // context.stroke();
            context.fill();
        },
        drawStartPoint(context, a, b, size, value, color) {
            context.beginPath();
            if (!color) color = this.getColor(value);
            context.fillStyle = color;
            context.arc(a, b, size, 0, 2 * Math.PI);
            context.fill();
        },
        getRadiusRelatedSize(val) {
            // related to start point
            let sz = 0.75 * this.configs.size;
            return val / sz;
        },
        getColor(val) {
            switch (true) {
                case Number(val) <= 2.49:
                    return "#E22D21";
                case Number(val) >= 2.50 && Number(val) <= 3.49:
                    return "#FAD03B";
                case Number(val) >= 3.50:
                    return "#2BC490";
                default:
                    return "#E22D21";
            }
        },
        getPointFromArc(posx, posy, radius, angle) {
            return [
                posx + Math.cos(angle) * radius,
                posy + Math.sin(angle) * radius
            ];
        },
        drawCenteredValues(context, valData, width, height, configs) {
            let x = width / 2;
            let y = height / 2;

            context.beginPath();
            context.moveTo(x, 0);
            context.lineTo(x, height);
            context.font = `700 ${0.1 * height}px Inter`;
            context.fillStyle = this.getColor(valData[0].value);
            context.textAlign = "center";
            context.fillText(valData[0].value, width / 2, height / 2);


            context.beginPath();
            context.moveTo(x, 0);
            context.lineTo(x, height);
            context.font = `500 ${0.08 * height}px Inter`;
            context.fillStyle = "#818181";
            context.textAlign = "center";
            context.fillText(valData[1].value, x, 0.75 * height);
        }
    }
};
</script>

<style lang="scss" scoped>
.ring {
    //text-align: left;
    //display: block;
    //width: 400px;
    //height: 240px;
}
</style>
