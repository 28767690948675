import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a67867a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stats-info-chart" }
const _hoisted_2 = { class: "stats-info-chart__item--value" }
const _hoisted_3 = { class: "stats-info-chart__item--label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.chartData, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["stats-info-chart__item", {
        [item.className]: item.className,
        [$data.getStatusClass(item.value)]: (item.showRangeColor && !item.isForGap),
        [`${$data.getGapStatusClass(item.value)}_gap`]: item.isForGap
      }]),
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.value), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(item.name)), 1)
      ], 2))
    }), 128))
  ]))
}