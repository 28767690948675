<template>
        <inline-svg
            :src="imagePath"
            class="country-img"
        />
</template>

<script>
import { PLATFORM_STATS_COLORS } from "@/core/config/constants";

export default {
    name: "CountryImage",
    props: {
        imagePath: String,
        score: Number,
    },
    computed: {
        getColor() {
            let score = parseFloat(this.score);
            if (score === 0 || score >= 3.50) {
                return PLATFORM_STATS_COLORS.GREEN;
            } else if (score <= 2.49) {
                return PLATFORM_STATS_COLORS.RED;
            } else if( score >= 2.50  && score  <= 3.49) {
                return PLATFORM_STATS_COLORS.YELLOW;
            }
        }
    }
};
</script>

<style lang="scss">
.country-img {
    width: 180px;
    height: 180px;

    path {
        fill: v-bind(getColor) !important;
        stroke: v-bind(getColor) !important;
        stroke-width: 10;
    }
}
</style>
