<template>
    <div class="dashboard-actions section-card">
        <div class="dashboard-actions__header">
            <div class="dashboard-actions__header--title">
                <img src="/media/buying/icons/call-to-actions.svg" alt="">
                <h4>{{ $t("Call To Actions") }}</h4>
                <span v-if="dataContainer.questionsSectionData.pagination">
                    {{ dataContainer.questionsSectionData.pagination.totalItems }}
                </span>
            </div>
            <DashboardSortSelect
                v-model="actionsSorting"
                :sortOptions="sortOptions"
                @onSortingChange="handleSortingChange"
            />
        </div>

        <div class="dashboard-actions__body">
            <div class="dashboard-actions__scrollbar">
                <div ref="questionsContainerItemLast"></div>

                <div
                    v-if="questionsData.questions && questionsData.questions.length"
                    class="bank-item"
                    v-for="(item, index) in questionsData.questions"
                    :key="index"
                >
                    <div class="bank-item__header">
                        <router-link :to="`/business/dashboard/banks/${item.bank.id}`">
                            <div class="bank-item__header--name">
                                <img :src="item.bank.icon_path" alt="">
                                <span>{{ item.bank.name }}</span>
                            </div>
                        </router-link>

                        <router-link :to="`/business/dashboard/product-areas/${item.question.product_area}`">
                            <div class="bank-item__header--product-area">
                                {{ item.question.product_area }}
                            </div>
                        </router-link>
                    </div>
                    <div class="bank-item__body">
                        <div class="bank-item__body--content" :class="getStatusClass(item.stats.score)">
                            <div class="content-header">
                                <div class="content-header__phases">
                                    {{ item.question.phase }}
                                </div>
                                <div class="content-header__qn-id">
                                    Qn ID
                                    <span>{{ item.question.id }}</span>
                                </div>
                            </div>
                            <div
                                class="content-question pointer"
                                :title="item.question.text"
                                @click="handleClickOnQuestion(item)"
                            >
                                {{ item.question.text }}
                            </div>
                        </div>
                    </div>
                    <div class="bank-item__footer">
                        <div class="footer-stats">
                            <div class="footer-stats-item stats-score" :class="getStatusClass(item.stats.score)">
                                <div class="footer-stats-label"></div>
                                <span>{{ $t("Score") }}</span>
                                <p>{{ numberDecimalsFormat(item.stats.score) }}</p>
                            </div>
                            <div class="footer-stats-item stats-imp">
                                <div class="footer-stats-label"></div>
                                <span>{{ $t("Imp") }}</span>
                                <p>{{ numberDecimalsFormat(item.stats.importance) }}</p>
                            </div>
                            <div class="footer-stats-item stats-gap" :class="getGapStatusClass(item.stats.gap)">
                                <div class="footer-stats-label"></div>
                                <span>{{ $t("Gap") }}</span>
                                <p>{{ numberDecimalsFormat(item.stats.gap) }}</p>
                            </div>
                            <div class="footer-stats-item stats-gap">
                                <div class="footer-stats-label"></div>
                                <span>{{ $t("Respondents") }}</span>
                                <p>{{ item.stats.respondents }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref="questionsContainerItemLastI"></div>

                <div
                    class="dashboard-actions__body--loading"
                    :class="{'w-100': questionsData.questions && !questionsData.questions.length}"
                    v-if="dataContainer.questionsSectionData.isLoading"
                >
                    <CustomLoader :hide-text="!!(questionsData.questions && questionsData.questions.length)" />
                </div>
            </div>

            <div class="w-100" v-if="questionsData.questions && !questionsData.questions.length && !dataContainer.questionsSectionData.isLoading">
                <DashboardEmptyState
                    icon="/media/buying/icons/no-call-to-actions.svg"
                    :title="$t('No Call To Actions found')"
                    :text="$t('This section shows the critical questions with low scores. Right now it seems to have none. Going good!')"
                    class-name="pt-5 pb-10"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { Constants } from "@/core/config/constants";
import { getStatusClass, getGapStatusClass, diagLog, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";
import DashboardSortSelect from "@/buying-teams/shared-components/inputs/DashboardSortSelect";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { BusinessDashboardOrderDirectionsEnum } from "@/store/enums/business/BusinessDashboardOrderDirectionsEnum";
import store from "@/store";

export default {
    name: "DashboardCallToActions",
    components: {
        CustomLoader,
        DashboardSortSelect,
        DashboardEmptyState,
    },
    props: {
        dataContainer: Object,
        questionsData: Object,
        filterData: Object,
    },
    emits: ['fetchQuestions'],
    data() {
        return {
            Constants,
            getStatusClass,
            getGapStatusClass,
            BusinessDashboardOrderDirectionsEnum,
            numberDecimalsFormat,
            actionsSorting: "lowest_score",
            sortOptions: [
                {
                    label: "Lowest Score",
                    key: 'lowest_score'
                },
                {
                    label: "Highest Negative Gap Score",
                    key: 'highest_negative_gap'
                }
            ],
        };
    },
    computed: {
        businessBaseFilterDefaultState() {
            return store.getters.businessBaseFilterDefaultState;
        }
    },
    mounted() {
        this.loadQuestions(true);
        this.registerInfiniteScroll();
    },
    methods: {
        handleSortingChange(sorting) {
            this.dataContainer.questionsSectionData.orderKey = sorting;
            this.dataContainer.addQuestionsSectionData([], this.dataContainer.questionsSectionData.pagination,  true);
            this.loadQuestions(true, true);
        },
        registerInfiniteScroll(e) {
            let sentinel = this.$refs.questionsContainerItemLastI;
            let intersectionObserver = new IntersectionObserver((entries, observer) => {
                if (entries.some(entry => entry.intersectionRatio > 0)) {
                    this.loadQuestions();
                }
            });
            intersectionObserver.observe(sentinel);
        },
        async loadQuestions(isForced = false, isSortingChanged = false) {
            this.$emit('fetchQuestions', { isForced, isSortingChanged });
        },
        handleClickOnQuestion(item) {
            let filter = {
                bank_ids: [item.bank.id],
                countries: this.filterData.countries || this.businessBaseFilterDefaultState.countries || [],
                survey_ids: [],
                time_period: this.filterData.time_period || this.businessBaseFilterDefaultState.time_period,
            };
            store.commit("SAVE_BUSINESS_SINGLE_QUESTION_FILTER", filter);
            this.$router.push(`/business/questions/${item.question.id}`);
        }
    }
};
</script>

<style scoped lang="scss">
.dashboard-actions {
    padding: 0 20px;
    min-height: 260px;
    overflow: hidden;

    &__header {
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--title {
            display: flex;
            align-items: center;

            img {
                margin-right: 8px;
            }

            h4 {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
                margin-right: 8px;
            }

            span {
                background: #ECECEC;
                border-radius: 3px;
                padding: 3px 7px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
            }
        }

        .main-btn-outline {
            background: rgba(67, 91, 244, 0.05) !important;
        }
    }

    &__body {
        overflow-x: auto;
        min-height: 210px;
        margin-left: -20px;
        margin-right: -20px;
        padding: 0 20px;
        position: relative;

        &--loading {
            width: 150px;
            height: 183px;
        }

        .bank-item {
            width: 550px;
            border: 1px solid #E1E1E1;
            border-radius: 8px;
            overflow: hidden;
            background: #FAFAFA;
            transition: .3s ease-in-out;

            &__header {
                padding: 16px;
                display: flex;
                justify-content: space-between;

                &--name {
                    img {
                        width: 26px;
                        margin-right: 9px;
                    }

                    span {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 26px;
                        color: #515151;
                    }
                }

                &--product-area {
                    background: #ECECEC;
                    border-radius: 4px;
                    padding: 8px 16px;
                    font-weight: 700;
                    font-size: 11px;
                    line-height: 13px;
                    color: #000000;
                }
            }

            &__body {
                padding: 13px 0;

                &--content {
                    padding: 0 16px;
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        width: 4px;
                        height: 54px;
                    }

                    .content-header {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 8px;

                        &__phases {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #030303;
                        }

                        &__qn-id {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #969696;

                            span {
                                font-weight: 600;
                                color: #0A0A0A;
                            }
                        }
                    }

                    .content-question {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: 0.1px;
                        color: #2A2A2A;
                        min-height: 78px;
                    }

                    &.status-danger:before {
                        background: #E22D21;
                    }

                    &.status-warning:before {
                        background: #FBDC6C;
                    }

                    &.status-success:before {
                        background: #2BC490;
                    }
                }
            }

            &__footer {
                padding: 10px 16px;

                .footer-stats {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    .footer-stats-item {
                        display: flex;
                        align-items: center;

                        .footer-stats-label {
                            width: 8.3px;
                            height: 8.3px;
                            border-radius: 50%;
                            background: #ECECEC;
                            margin-right: 7px;
                        }

                        span {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            color: #000000;
                            opacity: 0.5;
                            margin-right: 4px;
                        }

                        p {
                            margin-bottom: 0;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 16px;
                            color: #313131;
                        }

                        &.stats-score {
                            &.status-danger {
                                .footer-stats-label {
                                    background: #E22D21;
                                }
                            }

                            &.status-warning {
                                .footer-stats-label {
                                    background: #FBDC6C;
                                }
                            }

                            &.status-success {
                                .footer-stats-label {
                                    background: #2BC490;
                                }
                            }
                        }

                        &.stats-imp {
                            .footer-stats-label {
                                background: #CED4F8;
                            }

                            p {
                                font-weight: 400;
                            }
                        }

                        &.stats-gap {
                            .footer-stats-label {
                                position: relative;

                                &:after {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: 6px;
                                }
                            }

                            &.status-success {
                                .footer-stats-label {
                                    background-color: #DBF7ED;

                                    &:after {
                                        background-image: url("/media/buying/icons/gap-value-icon.svg");
                                        filter: invert(66%) sepia(12%) saturate(2047%) hue-rotate(108deg) brightness(93%) contrast(97%);
                                    }
                                }
                            }

                            &.status-danger {
                                .footer-stats-label {
                                    background-color: #FBDDDB;

                                    &:after {
                                        background-image: url("/media/buying/icons/gap-value-icon.svg");
                                        filter: invert(20%) sepia(88%) saturate(3117%) hue-rotate(353deg) brightness(96%) contrast(86%);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:hover {
                background: #fff;
                box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.09);
            }
        }
    }

    &__scrollbar {
        display: flex;
        width: fit-content;
        gap: 6px;
        padding-bottom: 18px;
        min-width: 100%;
    }

    @media (max-width: 550px) {
        .dashboard-actions__header {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }
    }
}
</style>

