<template>
    <div class="dashboard-session-item">
        <div class="dashboard-session-item__header">
            <div class="dashboard-session-item__title" :title="feedbackSession.title">
                {{ feedbackSession.title }}
            </div>
            <div class="dashboard-session-item__status">
                <StatusComponent
                    :text="feedbackSession.status.statusName"
                    :type="feedbackSession.status.statusClass"
                />
            </div>
        </div>

        <div class="dashboard-session-item__data">
            <div class="included-section">
                <div class="included-section__title">{{ $t("Banks included") }}</div>
                <el-tooltip placement="bottom-end" popper-class="included-section__popper" trigger="click">
                    <template #content>
                        <div
                            v-for="(bank, index) in feedbackSession.banks_data"
                            :key="index"
                            class="included-section__popper--item"
                        >{{ bank.name }}
                        </div>
                    </template>
                    <div class="included-section__count">{{ feedbackSession.banks_data.length }}</div>
                </el-tooltip>
            </div>
            <div class="included-section">
                <div class="included-section__title">{{ $t("Product Areas included") }}</div>
                <el-tooltip placement="bottom-end" popper-class="included-section__popper" trigger="click">
                    <template #content>
                        <div
                            v-for="(prArea, index) in feedbackSession.product_areas"
                            :key="index"
                            class="included-section__popper--item">{{ prArea }}
                        </div>
                    </template>
                    <div class="included-section__count">{{ feedbackSession.product_areas.length }}</div>
                </el-tooltip>
            </div>
        </div>

        <button
            v-if="currentUser.can(BusinessUserPermissionTypeEnum.REPLY_FEEDBACK_SESSION)"
            @click="handleReplyFeedback(feedbackSession.id)"
            class="dashboard-session-item__reply"
        >
            <img src="/media/buying/icons/buttonArrow.svg" width="9" alt="">
            {{ $t("Reply Now") }}
        </button>
        <div class="dashboard-session-item__completed">
            {{ $t("NUM of TOTAL completed", {
            num: feedbackSession.answered_questions,
            total: feedbackSession.total_questions
        }) }}
        </div>
    </div>
</template>

<script>
import StatusComponent from "@/buying-teams/shared-components/utils/StatusComponent";
import store from "@/store";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "DashboardSessionItem",
    components: { StatusComponent },
    props: {
        feedbackSession: Object
    },
    data() {
        return {
            BusinessUserPermissionTypeEnum
        }
    },
    computed: {
        currentUser() {
            return store.getters.currentUser;
        }
    },
    methods: {
        handleReplyFeedback(id) {
            this.$router.push(`/business/feedback/feedback-sessions?id=${id}`);
        }
    }
};
</script>

<style lang="scss">
.dashboard-session-item {
    padding: 12px 20px;
    width: 100%;
    height: 100%;
    text-align: left;
    //&__status {
    //  width: 103px;
    //  height: 25px;
    //  border-radius: 4px;
    //  border: 1px dashed #ccc;
    //}
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 13px;
        color: #000000;
        text-align: left;
        opacity: 0.87;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 103px);
        padding-right: 10px;
    }

    &__status {
        > * {
            white-space: nowrap;
        }
    }

    &__data {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 16px;

        .included-section {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__title {
                font-weight: 400;
                font-size: 12px;
                line-height: 13px;
                color: #696969;
                opacity: 0.87;
            }

            &__count {
                background: #E9E9E9;
                border-radius: 4px;
                padding: 3px 6px;
                font-weight: 700;
                font-size: 12px;
                line-height: 13px;
                color: #000000;
            }
        }
    }

    &__reply {
        width: 100%;
        border: none;
        background: #F4F5FE;
        border-radius: 44px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 9px;
        font-weight: 700;
        font-size: 12px;
        line-height: 13px;
        color: #435BF4;
        transition: .3s ease-in-out;
        margin-bottom: 12px;

        &:hover {
            background: #e9ecff;
        }
    }

    &__completed {
        font-weight: 300;
        font-size: 12px;
        line-height: 13px;
        color: #696969;
        opacity: 0.87;
    }
}
</style>
<style lang="scss">
.included-section__popper {
    background: #FFFFFF !important;
    box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.09);

    &--item {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }
}
</style>
