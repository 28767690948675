<template>
    <div class="dashboard-score section-card">
        <div class="dashboard-score__title">
            {{ title }}
        </div>
        <div class="dashboard-score__text">
            {{ description }}
        </div>
        <div class="dashboard-score__chart">
            <CircleChart
                :chartData="fillData.chartData"
                :chartConfigs="{
                  width: 320,
                  height: 220,
                  valueVisible: false
                }"
                :values="[fillData.chartData.score, fillData.chartData.importance]"
            />
        </div>

        <div class="stats-info-chart">
            <div class="stats-info-chart__item" :class="getGapStatusClass(fillData.chartData && fillData.chartData.gap ? fillData.chartData.gap : 0)">
                <div class="stats-info-chart__item--value">
                    {{ numberDecimalsFormat(fillData.chartData && fillData.chartData.gap ? fillData.chartData.gap : 0) }}
                </div>
                <div class="stats-info-chart__item--label">
                    <span></span>
                    {{ $t("Gap") }}
                </div>
            </div>
            <div class="stats-info-chart__item">
                <div class="stats-info-chart__item--value">
                    {{ fillData.respondents }}
                </div>
                <div class="stats-info-chart__item--label">
                    <span></span>
                    {{ $t("Respondents") }}
                </div>
            </div>
            <div class="stats-info-chart__item">
                <div class="stats-info-chart__item--value">
                    {{ fillData.scores }}
                </div>
                <div class="stats-info-chart__item--label">
                    <span></span>
                    {{ $t("Scores") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CircleChart from "@/buying-teams/shared-components/charts/CircleChart";
import StatsInfoChart from "@/buying-teams/shared-components/utils/StatsInfoChart";
import { getGapStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";

export default {
    name: "DashboardScore",
    components: {
        StatsInfoChart,
        CircleChart
    },
    props: {
        title: String,
        description: String,
        fillData: Object
    },
    data() {
        return {
            getGapStatusClass,
            numberDecimalsFormat
        };
    }
};
</script>

<style scoped lang="scss">
.dashboard-score {
    padding: 21px 24px 0 24px;

    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 13px;
        color: #000000;
        opacity: 0.87;
        margin-bottom: 10px;
    }

    &__text {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        opacity: 0.5;
    }

    &__chart {
        margin-left: -24px;
        margin-right: -24px;
    }

    .stats-info-chart {
        display: flex;
        justify-content: space-between;
        margin: 0 12px;
        margin-top: -16px;

        &__item {
            padding-bottom: 12px;
            position: relative;
            min-width: 70px;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                height: 4px;
                width: 100%;
                border-radius: 4px;
            }

            &--value {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: rgb(0, 0, 0, 0.87);
                margin-bottom: 8px;
            }

            &--label {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: rgb(0, 0, 0, 0.5);
                padding-left: 16px;
                position: relative;

                span {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background: #ECECEC;
                    width: 8.3px;
                    height: 8.3px;
                    border-radius: 50%;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 6px;
                    }
                }
            }

            &.status-success {
                &:after {
                    background: #63B775;
                }

                span {
                    background-color: #DBF7ED;

                    &:after {
                        background-image: url("/media/buying/icons/gap-value-icon.svg");
                        filter: invert(66%) sepia(12%) saturate(2047%) hue-rotate(108deg) brightness(93%) contrast(97%);
                    }
                }
            }

            &.status-danger {
                &:after {
                    background: #ED7B74;
                }

                span {
                    background-color: #FBDDDB;

                    &:after {
                        background-image: url("/media/buying/icons/gap-value-icon.svg");
                        filter: invert(20%) sepia(88%) saturate(3117%) hue-rotate(353deg) brightness(96%) contrast(86%);
                    }
                }
            }
        }
    }
}
</style>
