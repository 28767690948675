<template>
    <div @click="handleRedirection" class="request-idea-item-card">
        <div class="request-idea-item-card__header">
            <div class="request-idea__info">
                <div class="request-idea__info--name">
                    <img :src="fillData.creator.icon_path" alt="">
                    <span>{{ fillData.creator.full_name }}</span>
                </div>
                <div class="request-idea__info--job">
                    {{ fillData.creator.job_title }}
                </div>
                <div class="request-idea__info--date">
                    <b v-html="dateFormatInTimeZone(fillData.created_at)"></b>
                </div>
            </div>
            <div class="request-idea__bank-info">
                <div v-if="fillData.is_internal" class="request-idea__bank-info--status status-internal">
                    {{ $t("Internal") }}
                </div>
                <template v-else>
                    <div class="request-idea__bank-info--bank-name">
                        <img :src="fillData.bank.icon_path" alt="">
                        <span>{{ fillData.bank.name }}</span>
                    </div>
                    <div class="request-idea__bank-info--status status-shared">
                        {{ $t("Shared") }}
                    </div>
                </template>
            </div>
        </div>

        <div class="request-idea-item-card__body">
            {{ fillData.title }}
        </div>
    </div>
</template>

<script>
import { Constants } from "@/core/config/constants";
import { dateFormatInTimeZone } from "@/core/helpers/GlobalHelper";
import { BusinessIntentsTypeEnum } from "@/store/enums/BusinessIntentsTypeEnum";

export default {
    name: "RequestIdeaItemCard",
    props: {
        fillData: Object
    },
    data() {
        return {
            Constants,
            dateFormatInTimeZone
        };
    },
    methods: {
        handleRedirection() {
            if (this.fillData) {
                this.$router.push(`/business/feedback/${this.fillData.type}/${this.fillData.id}`);
            } else {
                console.error(`${this.fillData} not detected`);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.request-idea-item-card {
    padding: 16px 0 16px 18px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    cursor: pointer;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 13px;

        .request-idea {
            &__info {
                display: flex;
                align-items: center;

                &--name {
                    padding-right: 12px;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);

                    img {
                        width: 13px;
                        height: 13px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin-right: 6px;
                    }

                    span {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 158.02%;
                        color: #000000;
                    }
                }

                &--job {
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #969696;
                    padding-right: 12px;
                    padding-left: 12px;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                }

                &--date {
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #969696;
                    padding-left: 10px;

                    b {
                        font-weight: 700;
                        color: #2B2B2B;
                    }
                }
            }

            &__bank-info {
                display: flex;
                align-items: center;
                gap: 17px;

                &--bank-name {
                    img {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin-right: 8px;
                    }

                    span {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 158.02%;
                        color: #000000;
                    }
                }

                &--status {
                    border: 1px solid;
                    border-radius: 8px;
                    padding: 4px 8px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #000000;

                    &.status-shared {
                        background: #FFF7EE;
                        border-color: #FF930F;
                    }

                    &.status-internal {
                        background: #FAFAFA;
                        border-color: rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }

    &__body {
        font-weight: 400;
        font-size: 16px;
        line-height: 158.02%;
        color: #2B2B2B;
    }

    &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
    }


    @media (max-width: 600px) {
        &__header {
            flex-direction: column-reverse;
            gap: 10px;

            .request-idea__bank-info {
                justify-content: flex-end;
            }

            .request-idea__info {
                overflow-x: auto;

                > * {
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>
