<template>
    <div class="business-dashboard-overview" v-if="!loading">
        <div class="business-dashboard-overview__filter" :class="{'disabled-filter': !businessDashboardOverviewContainer}">
            <DashboardFilters
                ref="dashboardOverviewFilter"
                :dashboardOverviewFiltersState="dashboardOverviewFiltersState"
                @onApplyFilters="fetchDashboardData"
            />
        </div>

        <template v-if="businessDashboardOverviewContainer">
            <div class="business-dashboard-overview__score-sessions">
                <div class="overview-score">
                    <DashboardScore
                        :title="$t('Score')"
                        :description="$t('Based on all your Banks/filters applied')"
                        :fillData="businessDashboardOverviewContainer.scoreSectionData"
                    />
                </div>
                <div class="overview-sessions">
                    <DashboardSessions
                        :fillData="businessDashboardOverviewContainer.feedbackSessionsSectionData"
                    />
                </div>
            </div>

            <div class="business-dashboard-overview__call-to-actions">
                <DashboardCallToActions
                    :filterData="dashboardOverviewFiltersState"
                    :questionsData="businessDashboardOverviewContainer.questionsSectionData"
                    :dataContainer="businessDashboardOverviewContainer"
                    @fetchQuestions="fetchQuestionsData"
                />
            </div>

            <div class="business-dashboard-overview__bank-area">
                <div class="bank-product-area">
                    <DashboardCommonList
                        list-item-has-icon
                        :page-type="BusinessCommonListEnum.BANKS"
                        :score-data="businessDashboardOverviewContainer.banksSectionData"
                        :dataContainer="businessDashboardOverviewContainer"
                        :filter-data="dashboardOverviewFiltersState"
                    />
                </div>
                <div class="bank-product-area">
                    <DashboardCommonList
                        list-item-has-icon
                        :page-type="BusinessCommonListEnum.PRODUCT_AREA"
                        :score-data="businessDashboardOverviewContainer.productAreasSectionData"
                        :dataContainer="businessDashboardOverviewContainer"
                        :filter-data="dashboardOverviewFiltersState"
                    />
                </div>
            </div>

            <div class="business-dashboard-overview__countries">
                <DashboardCountries
                    :countriesSectionData="businessDashboardOverviewContainer.countriesSectionData"
                    :dataContainer="businessDashboardOverviewContainer"
                    :filterData="dashboardOverviewFiltersState"
                />
            </div>

            <div class="business-dashboard-overview__request-idea">
                <DashboardRequestsIdeas
                    :requestIdeaSectionData="businessDashboardOverviewContainer.requestIdeaSectionData"
                />
            </div>
        </template>
        <template v-else>
            <CustomLoader />
        </template>

    </div>
</template>

<script>
import DashboardScore from "@/buying-teams/pages/business/dashboard/components/overview/DashboardScore";
import DashboardSessions from "@/buying-teams/pages/business/dashboard/components/overview/DashboardSessions";
import DashboardCallToActions from "@/buying-teams/pages/business/dashboard/components/overview/DashboardCallToActions";
import DashboardCommonList from "@/buying-teams/shared-components/dashboard/DashboardCommonList";
import DashboardCountries from "@/buying-teams/pages/business/dashboard/components/overview/DashboardCountries";
import DashboardFilters from "@/buying-teams/pages/business/dashboard/components/overview/DashboardFilters";
import DashboardRequestsIdeas from "@/buying-teams/pages/business/dashboard/components/overview/DashboardRequestsIdeas";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import {
    BusinessDashboardOverviewContainer
} from "@/store/models/business/dashboard/BusinessDashboardOverviewContainer";
import { diagLog } from "@/core/helpers/GlobalHelper";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { BusinessCommonListEnum } from "@/store/enums/BusinessCommonListEnum";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";

export default {
    name: "Overview",
    components: {
        CustomLoader,
        DashboardRequestsIdeas,
        DashboardFilters,
        DashboardCountries,
        DashboardCommonList,
        DashboardCallToActions,
        DashboardSessions,
        DashboardScore
    },
    data() {
        return {
            loading: true,
            BusinessCommonListEnum,
            businessDashboardOverviewContainer: null
        };
    },
    computed: {
        businessData() {
            return store.getters.business;
        },
        dashboardOverviewFiltersState() {
            return store.getters.getBusinessFiltersState.dashboardOverview
        },
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.businessData.name,
            logo: this.businessData.icon_path
        });
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.DASHBOARD_OVERVIEW);
        this.loading = false;
        await this.fetchDashboardData(this.dashboardOverviewFiltersState);
    },
    methods: {
        async fetchDashboardData(filters = null) {
            try {
                diagLog({}, 'OVERVIEW FILTERS => ', { filters });
                this.businessDashboardOverviewContainer = null;
                let res = await store.dispatch("fetchBusinessDashboardData", filters);
                this.businessDashboardOverviewContainer = new BusinessDashboardOverviewContainer(res);

                this.fetchQuestionsData({ isForced: true });
                diagLog({}, { res });
                diagLog({}, { businessDashboardOverviewContainer: this.businessDashboardOverviewContainer });
            } catch (e) {
                console.error(e);
            }
        },
        async fetchQuestionsData({ isForced = false, isSortingChanged = true }) {
            if (
                this.businessDashboardOverviewContainer.questionsSectionData.isLoading || (
                    this.businessDashboardOverviewContainer.questionsSectionData.pagination
                    && !this.businessDashboardOverviewContainer.questionsSectionData.pagination.hasNextPage && !isSortingChanged
                )
            ) {
                return;
            }

            this.businessDashboardOverviewContainer.questionsSectionData.isLoading = true;
            try {
                if (isForced) {
                    this.businessDashboardOverviewContainer.questionsSectionData.page = 1;
                } else {
                    this.businessDashboardOverviewContainer.questionsSectionData.page += 1;
                }

                let payload = {
                    page: this.businessDashboardOverviewContainer.questionsSectionData.page,
                    limit: this.businessDashboardOverviewContainer.questionsSectionData.limit,
                    order_key: this.businessDashboardOverviewContainer.questionsSectionData.orderKey,
                    order_direction: this.businessDashboardOverviewContainer.questionsSectionData.orderDirection,
                    filter: this.$refs.dashboardOverviewFilter.filterData,
                    type: 'dashboard',
                };

                let res = await store.dispatch('fetchBusinessDashboardQuestions', payload);
                diagLog( `fetched res for page ${payload.page}`, {res, payload});
                this.businessDashboardOverviewContainer.addQuestionsSectionData(res.questions, res.paginationMetadata, isForced);
            } catch (e) {
                console.error({e});
            } finally {
                this.businessDashboardOverviewContainer.questionsSectionData.isLoading = false;
            }
        }
    }
};
</script>

<style lang="scss">
.business-dashboard-overview {
    gap: 12px;
    display: flex;
    flex-direction: column;

    &__filter {
        &.disabled-filter {
            .dashboard-filters__card {
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 4;
                    background: #F6F6F6;
                    opacity: 0.5;
                    border-radius: 17px;
                }
            }
        }
    }

    &__score-sessions {
        display: flex;
        gap: 12px;

        .overview-score {
            width: 338px;
        }

        .overview-sessions {
            width: calc(100% - 350px);
        }
    }

    .list-stats {
        display: flex;
        align-items: center;
        gap: 16px;

        .list-stats-item {
            display: flex;
            align-items: center;

            .list-stats-label {
                width: 8.3px;
                height: 8.3px;
                border-radius: 50%;
                background: #ECECEC;
                margin-right: 7px;
            }

            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                opacity: 0.5;
                margin-right: 4px;

            }

            p {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 0;
                color: #313131;
            }

            &.stats-score {
                &.status-danger {
                    .list-stats-label {
                        background: #E22D21;
                    }
                }

                &.status-warning {
                    .list-stats-label {
                        background: #FBDC6C;
                    }
                }

                &.status-success {
                    .list-stats-label {
                        background: #2BC490;
                    }
                }
            }

            &.stats-imp {
                .list-stats-label {
                    background: #CED4F8;
                }

                p {
                    font-weight: 400;
                }
            }

            &.stats-gap {
                .list-stats-label {
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 6px;
                    }
                }

                &.status-success {
                    .list-stats-label {
                        background-color: #DBF7ED;

                        &:after {
                            background-image: url("/media/buying/icons/gap-value-icon.svg");
                            filter: invert(66%) sepia(12%) saturate(2047%) hue-rotate(108deg) brightness(93%) contrast(97%);
                        }
                    }
                }

                &.status-danger {
                    .list-stats-label {
                        background-color: #FBDDDB;

                        &:after {
                            background-image: url("/media/buying/icons/gap-value-icon.svg");
                            filter: invert(20%) sepia(88%) saturate(3117%) hue-rotate(353deg) brightness(96%) contrast(86%);
                        }
                    }
                }
            }
        }
    }

    .section-card {
        background: #FFFFFF;
        box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
        border-radius: 17px;

        .section-card-header {
            height: 62px;
            padding: 10px 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .section-card-title {
            display: flex;
            align-items: center;
            gap: 8px;

            h2 {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
            }

            span {
                background: #ECECEC;
                border-radius: 3px;
                padding: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
            }
        }

        .section-card-actions {
            display: flex;
            align-items: center;
            gap: 22px;

            > a {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: #435BF4;
                opacity: 0.87;

                &:hover {
                    opacity: 1;
                }
            }

            &__sort {
                width: 28px;
                height: 28px;
                background: transparent;
                padding: 0;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &__bank-area {
        display: flex;
        gap: 12px;

        .bank-product-area {
            width: 50%;
        }
    }

    @media (max-width: 1200px) {
        &__score-sessions {
            flex-direction: column;

            .overview-score {
                width: 100%;
            }

            .overview-sessions {
                width: 100%;
            }
        }
        &__bank-area {
            flex-direction: column;

            .bank-product-area {
                width: 100%;
            }
        }
    }

    @media (max-width: 600px) {
        &__bank-area {
            .bank-product-area__card--header {
                .section-card-title h2 {
                    font-size: 16px;
                }

                .section-card-actions {
                    gap: 10px;

                    a {
                        font-size: 14px;
                    }

                    .el-input__prefix-inner {
                        img {
                            width: 18px;
                        }
                    }
                }
            }
        }
    }
}
</style>
