import { BankModelMin } from "@/store/models/bank/BankModelMin";

export class BusinessDashboardQuestionItem {
    stats: object;
    bank: BankModelMin;
    question: object;

    constructor(obj: any) {
        this.stats = obj.stats;

        this.bank = new BankModelMin(obj.bank);

        this.question = {
            id: obj.question.id,
            text: obj.question.question,
            product_area: obj.question.product_area,
            phase: obj.question.phase
        };
    }
}
