
import { getStatusClass, getGapStatusClass } from "@/core/helpers/GlobalHelper";

export default {
  name: "StatsInfoChart",
  props: {
    chartData: Array,
  },
  data() {
    return {
      getStatusClass,
      getGapStatusClass
    }
  }
}
