<template>
  <div
      v-if="text === SurveyStatuses.LIVE"
      class="d-flex align-items-center justify-content-end"
  >
    <img width="18" src="/media/buying/icons/liveIcon.svg" alt="">
    <p class="fw-bolder m-2">{{ $t(SurveyStatuses.LIVE) }}</p>
  </div>
  <span
      v-else
    :class="`status-label __${type}`">
    <b>{{ $t(text) }}</b>
  </span>
</template>

<script>
import { SurveyStatuses } from "@/store/models/SurveyModel";

export default {
  name: "StatusComponent",
  props: {
    text: String,
    type: String
  },
  data() {
    return {
      SurveyStatuses
    }
  }
};
</script>

<style scoped lang="scss">
.status-label {
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  &.__warning {
    background: #FDEFE3;
    color: #DE791C;
  }
  &.__secondary {
    background: #F1F1F1;
    color: #9CA0AB;
  }
  &.__primary {
    background: #EEF6FF;
    color: #435BF4;
  }
  &.__success {
    background: #E3F2EE;
    color: #28A57F;
  }
  &.__danger {
    background: #FFEDED;
    color: #E70C0C;
  }
}
</style>
